import React from 'react'
import { CardElement, Elements, injectStripe, StripeProvider } from 'react-stripe-elements'
import { getStripePublicKey } from '../../../utils'

const CardForm: React.FC<{}> = () => {
  return (
    <form>
      <label>
        Card details
        <CardElement />
      </label>
      <button>Pay</button>
    </form>
  )
}

const StripeInjectedCardForm = injectStripe(CardForm)

const Checkout: React.FC<{}> = () => {
  return (
    <div className="Checkout">
      <h1>Available Elements</h1>
      <Elements>
        <StripeInjectedCardForm />
      </Elements>
    </div>
  )
}

const CardList = () => {
  return (
    <StripeProvider apiKey={getStripePublicKey()}>
      <Checkout />
    </StripeProvider>
  )
}

export default CardList
