export function getMergedFilterParams(fields, filterValue = {}) {
  const currentParams = new URLSearchParams(window.location.search)
  const filterFields = Object.keys(filterValue)
  const params: any = {}

  fields.forEach(param => {
    if (!filterFields.includes(param) && currentParams.has(param)) {
      params[param] = currentParams.get(param)
    }
    if (!filterFields.includes(param) && currentParams.has(`${param}[]`)) {
      params[param] = currentParams.getAll(`${param}[]`)
    }
  })

  filterFields.forEach(filterField => {
    if (
      filterValue[filterField] === null ||
      (Array.isArray(filterValue[filterField]) && filterValue[filterField].length === 0)
    ) {
      delete params[filterField]
    } else {
      params[filterField] = filterValue[filterField]
    }
  })

  return params
}

export function getMergedFilterQueryString(fields, params): string {
  return fields
    .filter(param => params[param])
    .map(param =>
      Array.isArray(params[param])
        ? params[param].map(value => `${param}[]=${value}`).join('&')
        : `${param}=${params[param]}`
    )
    .join('&')
}
