import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import uuid from 'uuid'
import FlashMessage from './Message'

interface IWindow {
  flashMessages: FlashMessages
}
declare var window: IWindow

interface IProps {
  messages: IMessage[]
}

interface IState {
  messages: IMessage[]
}

interface IMessage {
  text: string
  type: string
  uuid: string
}

export default class FlashMessages extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = {
      messages: props.messages || [],
    }
    window.flashMessages = this
  }

  public addMessage = message => {
    const messages = [
      ...this.state.messages,
      {
        ...message,
        uuid: uuid(),
      },
    ]
    this.setState({ messages })
  }

  public deleteMessage = id => {
    const messages = this.state.messages.filter(message => message.uuid !== id)
    this.setState({ messages })
  }

  public render() {
    return (
      <SFlashMessages>
        <TransitionGroup>
          {this.state.messages.map((message, index) => (
            <CSSTransition key={message.uuid || uuid()} classNames="fade" timeout={200}>
              <FlashMessage
                key={message.uuid}
                uuid={message.uuid}
                type={message.type}
                deleteMessage={this.deleteMessage}
              >
                {message.text}
              </FlashMessage>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </SFlashMessages>
    )
  }
}

const SFlashMessages = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`
