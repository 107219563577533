import React from 'react'

const Dashboard: React.FC<{}> = props => {
  return (
    <>
      <h1>Dashboard</h1>
    </>
  )
}

export default Dashboard
